<template>
    <el-dialog
        :visible.sync="modalAddDamage"
        :before-close="closeModal"
        width="600px"
        top="20px"
        :close-on-click-modal="false"
        @open="modalOpen"
        @closed="resetForm"
    >
        <span slot="title">
            <div class="absolute w-full top-0 inset-x-0 border-b rounded-t-lg p-4 bg-gray-100">
                <p>
                    {{ $t('clients.add_damage') }}
                </p>
            </div>
        </span>

        <div v-loading="$wait.is('loading.add_damage')" class="flex justify-center mb-14 mt-8 px-12" element-loading-spinner="el-custom-spinner">
            <el-form ref="form" :model="formData" label-position="top" :rules="rules" class="w-full">
                <el-form-item :label="$t('clients.status')" class="w-full">
                    <el-select v-model="formData.status" class="w-full">
                        <el-option :value="0" :label="$t('clients.open')" />
                        <el-option :value="1" :label="$t('clients.solved')" />
                    </el-select>
                </el-form-item>

                <el-form-item :label="$t('clients.description')" prop="description" class="w-full">
                    <el-input
                        v-model="formData.description"
                        type="textarea"
                        :rows="3"
                        resize="none"
                    />
                </el-form-item>

                <el-form-item :label="$t('clients.amount')" prop="amount" class="w-full">
                    <money v-model="formData.amount" class="el-input__inner" v-bind="money" />
                </el-form-item>

                <el-form-item :label="$t('clients.address')" prop="addressUuid" class="w-full">
                    <el-select v-model="formData.addressUuid" class="w-full">
                        <el-option v-for="item in addressesData" :key="item.uuid" :value="item.uuid" :label="`${item.street}, ${item.city} ${item.post_code}`" />
                    </el-select>
                </el-form-item>

                <el-form-item :label="$t('clients.employee')" class="w-full">
                    <EmployeesDropdown
                        ref="employeeDropdown"
                        :withArchive="false"
                        :withoutQuerySearch="true"
                        :multiple="false"
                        customClass="w-full"
                        @selected="employeeSelected"
                    />
                </el-form-item>
            </el-form>
        </div>

        <div class="flex justify-end rounded-b-lg border-t w-full p-3 bg-gray-100 absolute bottom-0 inset-x-0">
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
                <el-button type="primary" :loading="$wait.is('loading.add_damage')" @click="add">{{ $t('common.add') }}</el-button>
            </span>
        </div>
    </el-dialog>
</template>
<script>
import Vue from 'vue';
import { Money } from 'v-money';
import Api from './damages.api';

Vue.use(Money);

export default {
    components: {
        Money,
        EmployeesDropdown: () => import(/* webpackChunkName: "EmployeesDropdown" */ '@/components/EmployeesDropdown'),
    },

    props: {
        modalAddDamage: {
            type:    Boolean,
            default: false,
        },
        clientUuid: {
            type:    String,
            default: null,
        },
    },

    data() {
        return {
            addressesData: [],
            money:         {
                decimal:   ',',
                thousands: '',
                precision: 2,
                masked:    true,
            },
            formData: {
                status:       0,
                amount:       0,
                description:  '',
                addressUuid:  null,
                employeeUuid: null,
            },
            rules: {
                amount:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                description: [
                    { required: true, message: this.$t('common.required'), trigger: 'blur' },
                    { min: 10, message: this.$t('common.required'), trigger: 'blur' },
                ],
                addressUuid: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    methods: {
        modalOpen() {
            this.getAddresses();
        },

        async getAddresses() {
            this.$wait.start('loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${this.clientUuid}/addresses`);
            this.addressesData = data;
            this.$wait.end('loading');
        },

        async add() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$wait.start('loading.add_damage');

            try {
                await Api.create(this.formData);
                this.$notify.success({ title: this.$t('common.success') });
                this.$emit('refresh');
                this.closeModal();
            } finally {
                this.$wait.end('loading.add_damage');
            }
        },

        employeeSelected(employeeUuid) {
            this.formData.employeeUuid = employeeUuid;
        },

        closeModal() {
            this.$emit('close');
        },

        resetForm() {
            this.formData = {
                status:       0,
                amount:       0,
                description:  '',
                addressUuid:  null,
                employeeUuid: null,
            };
            this.$refs.employeeDropdown.resetSelection();
            this.$refs.form.resetFields();
        },
    },
};
</script>
